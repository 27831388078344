import React, { useReducer } from "react";
import alertContext from "./alertContext";
import alertReducer from "./alertReducer";

import { SET_ALERT, DELETE_ALERT } from "../../types/index.js";

const AlertState = props => {
  const initialState = {
    alert: null
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);

  const setAlert = (msg, category) => {
    dispatch({
      type: SET_ALERT,
      payload: {
        msg,
        category
      }
    });

    setTimeout(() => {
      dispatch({
        type: DELETE_ALERT
      });
    }, 5000);
  };

  return (
    <alertContext.Provider value={{
      alert: state.alert,
      setAlert
    }}>{props.children}</alertContext.Provider>
  );
};

export default AlertState;
