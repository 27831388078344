import React, { useContext, useReducer, useEffect, useState } from "react";
import buscadorContext from "./buscadorContext";
import buscadorReducer from "./buscadorReducer";
import AuthContext from "../auth/authContext";
import AlertContext from "../alerts/alertContext";
import axiosClient from "../../config/axios";

const BuscadorState = (props) => {
  const authContext = useContext(AuthContext);
  const { header, getUser } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialState = {};

  const [state, dispatch] = useReducer(buscadorReducer, initialState);
  const [personasBuscadas, setPersonasBuscadas] = useState({});
  const [datosPersona, setDatosPersona] = useState({});
  const [movimientos, setMovimientos] = useState({});
  const [saldoCuenta, setSaldoCuenta] = useState(0);
  const [loadingBusqueda, setLoadingBusqueda] = useState(false);
  const [loadingMovimientos, setLoadingMovimientos] = useState(false);
  const [loadingFiltrado, setLoadingFiltrado] = useState(false);
  const [datosFiltros, setDatosFiltros] = useState({
    desde: "",
    hasta: "",
    saldoCero: false,
  });

  useEffect(() => {}, []);

  const buscarPersonasAPI = async (data) => {
    setPersonasBuscadas({});
    setLoadingBusqueda(true);
    await axiosClient
      .post("/get_personas", data, header)
      .then((response) => {
        if (response.data.data.length === 0) {
          setPersonasBuscadas({});
          setAlert("No se encontraron personas", "red-300");
        } else {
          setPersonasBuscadas(response.data.data);
        }
        setLoadingBusqueda(false);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          console.log(err);
          setLoadingBusqueda(false);
        }
      });
  };

  const updateMovimientosPersona = async (dni) => {
    var data = {
      idEmpresa: 2, // Por ahora va harcodeado como 2 porque es el CAER
      dni: dni,
      tipo: 2, // Este tipo trae los recibos NO informados de la persona
    };
    await axiosClient
      .post("update_recibos_cardinal", data, header)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setLastUpdateLocalStorage = () => {
    var now = new Date();
    var day =
      now.getDate() < 10 ? "0" + now.getDate().toString() : now.getDate();
    var month =
      parseInt(now.getMonth() + 1) < 10
        ? "0" + now.getMonth().toString()
        : parseInt(now.getMonth() + 1);
    var year = now.getFullYear();

    var hour =
      now.getHours() < 10 ? "0" + now.getHours().toString() : now.getHours();
    var minutes =
      now.getMinutes() < 10
        ? "0" + now.getMinutes().toString()
        : now.getMinutes();
    var seconds =
      now.getSeconds() < 10
        ? "0" + now.getSeconds().toString()
        : now.getSeconds();
    localStorage.setItem(
      "lastUpdate",
      day +
        "/" +
        month +
        "/" +
        year +
        " - " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds
    );
  };

  const getMovimientosPersona = async (idPersona, dni, noFilter) => {
    if (
      noFilter === undefined &&
      (datosFiltros.desde !== "" || datosFiltros.hasta !== "")
    ) {
      filtrarMovimientos();
    } else {
      const data = {
        idPersona: idPersona,
        saldoCero: "false",
      };
      setDatosFiltros({
        desde: "",
        hasta: "",
        saldoCero: false,
      });
      setMovimientos({});
      setLoadingMovimientos(true);

      updateMovimientosPersona(dni).then(async () => {
        await axiosClient
          .post("get_movimientos_personas", data, header)
          .then((response) => {
            if (response.data.data.length === 0) {
              setSaldoCuenta(0);
              setMovimientos({});
              setLastUpdateLocalStorage();
            } else {
              setLastUpdateLocalStorage();
              setSaldoCuenta(response.data.data.total);
              setMovimientos(response.data.data.movimientos);
            }
            setLoadingMovimientos(false);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              resetStateBuscador();
              getUser();
            } else {
              setAlert("Hubo un error intentá de nuevo", "red-300");
              setLoadingMovimientos(false);
              console.log(err);
            }
          });
      });
    }
  };

  const filtrarMovimientos = async () => {
    if (
      datosFiltros.saldoCero === true &&
      (datosFiltros.desde === "" || datosFiltros.hasta === "")
    ) {
      setAlert("Por favor, completá las dos fechas", "yellow-300");
      return false;
    }
    const data = {
      idPersona: datosPersona.ID,
      saldoCero: datosFiltros.saldoCero,
      desde: datosFiltros.desde,
      hasta: datosFiltros.hasta,
    };
    //setMovimientos({});
    setLoadingFiltrado(true);
    await axiosClient
      .post("get_movimientos_personas", data, header)
      .then((response) => {
        if (response.data.data.length === 0) {
          //setSaldoCuenta(0);
          //setMovimientos({});
          setAlert(
            "No se encontraron movimientos con los filtros especificados",
            "yellow-300"
          );
        } else {
          //setSaldoCuenta(response.data.data.total);
          setMovimientos(response.data.data.movimientos);
        }
        setLoadingFiltrado(false);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          setLoadingFiltrado(false);
          console.log(err.response);
        }
      });
  };

  const formatDate = (data) => {
    var date = new Date(data);

    if (!isNaN(date.getTime())) {
      var day = date.getUTCDate().toString();
      var month = (date.getUTCMonth() + 1).toString();
      var fecha =
        (day[1] ? day : "0" + day[0]) +
        "/" +
        (month[1] ? month : "0" + month[0]) +
        "/" +
        date.getFullYear();
      return fecha;
    }
  };

  const formatImporte = (data) => {
    const total = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(data);

    return "$ " + total;
  };

  const resetStateBuscador = () => {
    setMovimientos({});
    setPersonasBuscadas({});
    setDatosPersona({});
    setSaldoCuenta(0);
    setLoadingBusqueda(false);
    setLoadingMovimientos(false);
  };

  return (
    <buscadorContext.Provider
      value={{
        personasBuscadas,
        setPersonasBuscadas,
        buscarPersonasAPI,
        loadingBusqueda,
        setLoadingBusqueda,
        movimientos,
        setMovimientos,
        loadingMovimientos,
        setLoadingMovimientos,
        getMovimientosPersona,
        datosPersona,
        setDatosPersona,
        formatDate,
        formatImporte,
        saldoCuenta,
        setSaldoCuenta,
        resetStateBuscador,
        datosFiltros,
        setDatosFiltros,
        filtrarMovimientos,
        loadingFiltrado,
        setLoadingFiltrado,
      }}
    >
      {props.children}
    </buscadorContext.Provider>
  );
};

export default BuscadorState;
