import React, { useContext, useState, useEffect } from "react";
import axiosClient from "../../config/axios";
import BuscadorContext from "../../context/buscador/buscadorContext";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alerts/alertContext";
import MovimientosContext from "../../context/movimientos/movimientosContext";
import ModalAltaMovimiento from "../Movimientos/ModalAltaMovimiento";
import ModalAplicadoMovimiento from "../Movimientos/ModalAplicarMovimiento";
import ModalAplicarMultiple from "../Movimientos/ModalAplicarMultiple";
import ModalReaplicar from "../Movimientos/ModalReaplicar";
import ModalConfirmacion from "../Movimientos/ModalConfirmacion";
import ModalConfirmacionAplicado from "../Movimientos/ModalConfirmacionAplicado";
import ModalConfirmacionReaplicado from "../Movimientos/ModalConfirmacionReaplicar";
import ModalConfirmacionDesplicado from "../Movimientos/ModalConfirmarDesaplicar";
import ModalConfirmacionEliminar from "../Movimientos/ModalConfirmacionEliminar";

function Movimientos() {
  const authContext = useContext(AuthContext);
  const { header, getUser } = authContext;
  const buscadorContext = useContext(BuscadorContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const {
    datosPersona,
    movimientos,
    loadingMovimientos,
    formatDate,
    formatImporte,
    saldoCuenta,
    resetStateBuscador,
    datosFiltros,
    setDatosFiltros,
    filtrarMovimientos,
    loadingFiltrado,
    getMovimientosPersona,
  } = buscadorContext;
  const movimientosContext = useContext(MovimientosContext);
  const {
    modalAltaMovimiento,
    setModalAltaMovimiento,
    modalAplicadoMovimiento,
    setModalAplicadoMovimiento,
    modalConfirmacion,
    modalConfirmacionAplicado,
    modalConfirmacionEliminar,
    setModalConfirmacionEliminar,
    setIdMovimientoSeleccionado,
    recibosAplicados,
    setRecibosAplicados,
    mostrarRecibosAplicados,
    setMostrarRecibosAplicados,
    modalAplicarMultiple,
    setModalAplicarMultiple,
    modalReaplicado,
    setModalReaplicado,
    modalConfirmacionReaplicado,
    modalDesaplicado,
    setModalDesaplicado,
    resetStateMovimientos,
  } = movimientosContext;

  const [actionsModalOpen, setActionsModalOpen] = useState({});
  const [actionsAplicadosModal, setActionsAplicadosModal] = useState({});
  const [cargandoRecibosAplicados, setCargandoRecibosAplicados] = useState({});

  useEffect(() => {
    setActionsAplicadosModal({});
    setRecibosAplicados({});
    setMostrarRecibosAplicados({});
  }, [movimientos]);
  useEffect(() => {}, [saldoCuenta]);

  const handleActionsModal = (id) => {
    const newActionsModal = { ...actionsModalOpen };
    newActionsModal[id] = !newActionsModal[id];
    for (var [key, value] of Object.entries(newActionsModal)) {
      if (key !== id) {
        newActionsModal[key] = false;
      }
    }
    const newActionsAplicadosModal = { ...actionsAplicadosModal };
    for (var [key, value] of Object.entries(newActionsAplicadosModal)) {
      newActionsAplicadosModal[key] = false;
    }
    setActionsModalOpen(newActionsModal);
    setActionsAplicadosModal(newActionsAplicadosModal);
  };

  const handleAplicadosActionModal = (id) => {
    const newActionsAplicadosModal = { ...actionsAplicadosModal };
    newActionsAplicadosModal[id] = !newActionsAplicadosModal[id];
    for (var [key, value] of Object.entries(newActionsAplicadosModal)) {
      if (key !== id) {
        newActionsAplicadosModal[key] = false;
      }
    }
    const newActionsModal = { ...actionsModalOpen };
    for (var [key, value] of Object.entries(newActionsModal)) {
      newActionsModal[key] = false;
    }
    setActionsModalOpen(newActionsModal);
    setActionsAplicadosModal(newActionsAplicadosModal);
  };

  const handleCargarRecibosAplicados = async (id) => {
    if (recibosAplicados[id] === undefined) {
      const newCargarRecibos = { ...cargandoRecibosAplicados };
      newCargarRecibos[id] = true;
      setCargandoRecibosAplicados(newCargarRecibos);
      await axiosClient
        .get("get_movimientos_aplicados/" + id, header)
        .then((response) => {
          if (response.data.data.length === 0) {
            setAlert(
              "Este movimiento no tiene movimientos aplicados",
              "yellow-300"
            );
            const newCargarRecibos = { ...cargandoRecibosAplicados };
            newCargarRecibos[id] = false;
            setCargandoRecibosAplicados(newCargarRecibos);
          } else {
            const newRecibosAplicados = { ...recibosAplicados };
            newRecibosAplicados[id] = response.data.data;
            setRecibosAplicados(newRecibosAplicados);
            const newCargarRecibos = { ...cargandoRecibosAplicados };
            newCargarRecibos[id] = false;
            setCargandoRecibosAplicados(newCargarRecibos);
            const newMostrarRecibos = { ...mostrarRecibosAplicados };
            newMostrarRecibos[id] = true;
            setMostrarRecibosAplicados(newMostrarRecibos);
          }
        })
        .catch((err) => {
          const newCargarRecibos = { ...cargandoRecibosAplicados };
          newCargarRecibos[id] = false;
          setCargandoRecibosAplicados(newCargarRecibos);
          if (err.response.status === 403) {
            resetStateBuscador();
            resetStateMovimientos();
            getUser();
          } else {
            setAlert("Hubo un error intentá de nuevo", "red-300");
            console.log(err);
          }
        });
    } else {
      const newMostrarRecibos = { ...mostrarRecibosAplicados };
      newMostrarRecibos[id] = !newMostrarRecibos[id];
      setMostrarRecibosAplicados(newMostrarRecibos);
    }
  };

  const updateFiltros = (e) => {
    const newFiltros = { ...datosFiltros };
    newFiltros[e.target.name] = e.target.value;
    setDatosFiltros(newFiltros);
  };

  const updateSaldoCero = () => {
    const newFiltros = { ...datosFiltros };
    newFiltros.saldoCero = !newFiltros.saldoCero;
    setDatosFiltros(newFiltros);
  };

  return (
    <div className="mb-3">
      {!loadingMovimientos && datosPersona.ID !== undefined ? (
        <div>
          <div className="flex justify-between">
            <div>
              <h1 className="font-bold text-xl">Movimientos</h1>
              <h2 className="font-medium text-gray-400 mb-1 gap-2">
                {datosPersona.TIPOPERSONA === "F"
                  ? datosPersona.NOMBRE + " " + datosPersona.APELLIDO
                  : datosPersona.OBSERVACIONES}{" "}
                <p className="text-gray-900">
                  {"Última Actualización: " +
                    localStorage.getItem("lastUpdate")}
                </p>
              </h2>
            </div>
            <div className="flex flex-row gap-2">
              <button
                className="bg-green-600 text-white px-12 h-8 mt-1"
                onClick={() => {
                  setModalAltaMovimiento(true);
                }}
              >
                NUEVO
              </button>
              <button
                className="bg-blue-800 text-white px-7 h-8 mt-1"
                onClick={() => {
                  setDatosFiltros({ desde: "", hasta: "", saldoCero: false });
                  getMovimientosPersona(
                    datosPersona.ID,
                    datosPersona.NRO_DOCUMENTO,
                    true
                  );
                }}
              >
                ACTUALIZAR
              </button>
            </div>
          </div>
          <div className="mb-2 border-2 border-gray-400 w-full p-3">
            <div className="border border-l-0 border-r-0 border-t-0 border-black mb-2">
              <h2 className="font-medium">FILTROS</h2>
            </div>
            <div className="flex flex-wrap w-full">
              <div className="flex flex-col">
                <label>Fecha Inicio</label>
                <input
                  name="desde"
                  id="desde"
                  type="date"
                  value={datosFiltros.desde}
                  onChange={updateFiltros}
                  className="bg-gray-200 border border-black mr-4 py-0.5"
                ></input>
              </div>
              <div className="flex flex-col">
                <label>Fecha Fin</label>
                <input
                  name="hasta"
                  id="hasta"
                  type="date"
                  value={datosFiltros.hasta}
                  onChange={updateFiltros}
                  className="bg-gray-200 border border-black py-0.5"
                ></input>
              </div>
              <div className="flex flex-col ml-4">
                <label>Saldo Cero</label>
                <button
                  className={
                    datosFiltros.saldoCero
                      ? "bg-green-400 border border-black"
                      : "bg-red-500 border border-black"
                  }
                  style={{ height: 30, width: 36 }}
                  onClick={() => updateSaldoCero()}
                >
                  {datosFiltros.saldoCero ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class=" h-5 w-5 text-white"
                      style={{ marginLeft: 7 }}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-white"
                      style={{ marginLeft: 7 }}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>

            <button
              className="bg-blue-800 text-white px-10 h-8 mt-3"
              onClick={() => filtrarMovimientos()}
            >
              FILTRAR
            </button>
            <br></br>
            <button
              className="bg-red-700 text-white px-10 h-8 mt-2 "
              onClick={() => {
                setDatosFiltros({
                  desde: "",
                  hasta: "",
                  saldoCero: false,
                });
              }}
            >
              LIMPIAR
            </button>
          </div>
        </div>
      ) : null}
      {loadingMovimientos || loadingFiltrado ? (
        <div className="border border-black p-4 items-center mt-10">
          <div className="flex flex-row text-center items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="text-gray-500 animate-spin h-5 w-5 mr-3"
            >
              <path
                fill-rule="evenodd"
                d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                clip-rule="evenodd"
              />
            </svg>
            <h1 className="text-gray-500 font-bold text-xl">
              Cargando movimientos
            </h1>
          </div>
        </div>
      ) : movimientos.length > 0 ? (
        movimientos.map((m) => (
          <div key={m.ID}>
            <div className="border-2 border-gray-400 p-4 mb-3 lg:grid lg:grid-cols-8 lg:gap-4">
              <div className=" lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">CÓDIGO</p>
                <p className="text-sm font-mono">{m.CODCAB}</p>
              </div>
              <div className=" lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">NÚMERO</p>
                <p className="text-sm font-mono ">{m.NROCAB}</p>
              </div>
              <div className=" lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">EMISIÓN</p>
                <p className="text-sm font-mono">
                  {formatDate(m.FECHAEMISION)}
                </p>
              </div>
              <div className=" lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">VENCIMIENTO</p>
                <p className="text-sm font-mono ">
                  {formatDate(m.FECHAVENCIMIENTO)}
                </p>
              </div>
              <div className=" lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">DESCRIPCIÓN</p>
                <div className="flex flex-row">
                  <p className="text-sm font-mono">{m.DESCRIPCION}</p>
                </div>
              </div>
              <div className="lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                <p className="text-sm font-mono ">{formatImporte(m.TOTAL)}</p>
              </div>
              <div className="lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">SALDO</p>
                <p className="text-sm font-mono ">{formatImporte(m.SALDO)}</p>
              </div>
              <div className="lg:m-0 mb-2 flex justify-end items-start">
                <button
                  className="px-2 py-1 border border-black flex text-sm items-center mr-2"
                  onClick={() => handleActionsModal(m.ID)}
                >
                  <p className="hidden 2xl:block">ACCIONES</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 2xl:h-4 2xl:w-4 2xl:ml-2"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  className={
                    actionsModalOpen[m.ID]
                      ? "font-mono border-2 border-gray-500 transition ease-out duration-100 transform opacity-100 scale-100 z-10 mt-8 origin-top-left xl:origin-top-right xl:right-0 absolute xl:mr-20 w-48 shadow-lg py-1 bg-gray-200 z-10"
                      : "font-mono border-2 border-gray-500 transition ease-in duration-75 transform opacity-0 scale-0 z-10 mt-8 origin-top-left xl:origin-top-right xl:right-0 absolute xl:mr-20 w-48 shadow-lg py-1 bg-gray-200 z-10"
                  }
                >
                  <button
                    type="button"
                    className="block w-full font-medium border-b-2 border-gray-500 px-4 py-2 text-xs flex justify-start"
                    role="menuitem"
                    onClick={() => {
                      setIdMovimientoSeleccionado(m.ID);
                      setModalAplicadoMovimiento(true);
                    }}
                  >
                    Nuevo Aplicado
                  </button>
                  <button
                    type="button"
                    className="block w-full font-medium text-left border-b-2 border-gray-500 px-4 py-2 text-xs flex justify-start"
                    role="menuitem"
                    onClick={() => {
                      setIdMovimientoSeleccionado(m.ID);
                      setModalAplicarMultiple(true);
                    }}
                  >
                    Nuevo Aplicado Múltiple
                  </button>
                  <button
                    type="button"
                    className="block w-full font-medium border-b-2 border-gray-500 px-4 py-2 text-xs flex justify-start"
                    role="menuitem"
                    onClick={() => {
                      setIdMovimientoSeleccionado(m.ID);
                      setModalReaplicado(true);
                    }}
                  >
                    Reaplicar
                  </button>
                  <button
                    type="button"
                    className="block w-full font-medium px-4 py-2 text-xs flex justify-start"
                    role="menuitem"
                    onClick={() => {
                      setIdMovimientoSeleccionado(m.ID);
                      setModalConfirmacionEliminar(true);
                    }}
                  >
                    Eliminar
                  </button>
                </div>
                <button
                  className="bg-blue-800 px-2 py-1 border border-black flex text-xs items-center"
                  onClick={() => handleCargarRecibosAplicados(m.ID)}
                  disabled={cargandoRecibosAplicados[m.ID]}
                >
                  {cargandoRecibosAplicados[m.ID] ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="animate-spin h-5 w-5 text-white"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  ) : mostrarRecibosAplicados[m.ID] ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5 text-white"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5 text-white"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            {mostrarRecibosAplicados[m.ID] &&
            recibosAplicados[m.ID] !== undefined
              ? recibosAplicados[m.ID].map((r) => (
                  <div
                    key={r.ID}
                    className="border-2 border-gray-400 p-4 mb-3 ml-5 lg:grid lg:grid-cols-8 lg:gap-4"
                  >
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">CÓDIGO</p>
                      <p className="text-sm font-mono">{r.CODAP}</p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">NÚMERO</p>
                      <p className="text-sm font-mono ">{r.NROAP}</p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">EMISIÓN</p>
                      <p className="text-sm font-mono">
                        {formatDate(r.FECHAEMISION)}
                      </p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">
                        VENCIMIENTO
                      </p>
                      <p className="text-sm font-mono ">
                        {formatDate(r.FECHAVENCIMIENTO)}
                      </p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">
                        DESCRIPCIÓN
                      </p>
                      <p className="text-sm font-mono">{r.DESCRIPCION}</p>
                    </div>
                    <div className="lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                      <p className="text-sm font-mono ">
                        {formatImporte(r.TOTAL)}
                      </p>
                    </div>
                    <div className="lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">SALDO</p>
                      <p className="text-sm font-mono ">
                        {formatImporte(r.SALDO)}
                      </p>
                    </div>
                    <div className="lg:m-0 mb-2 flex justify-end items-center">
                      <button
                        className="px-2 py-1 border border-black flex text-sm items-center"
                        onClick={() => handleAplicadosActionModal(r.ID)}
                      >
                        ACCIONES
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="h-4 w-4 ml-2"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                      <div
                        className={
                          actionsAplicadosModal[r.ID]
                            ? "font-mono border-2 border-gray-500 transition ease-out duration-100 transform opacity-100 scale-100 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute xl:mr-20 w-48 shadow-lg py-1 bg-gray-200 z-10"
                            : "font-mono border-2 border-gray-500 transition ease-in duration-75 transform opacity-0 scale-0 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute xl:mr-20 w-48 shadow-lg py-1 bg-gray-200 z-10"
                        }
                        style={{ marginTop: "7.1rem", marginRight: "2.1rem" }}
                      >
                        <button
                          type="button"
                          className="block w-full font-medium px-4 border-b-2 border-gray-500 py-2 text-xs flex justify-start"
                          role="menuitem"
                          onClick={() => {
                            setIdMovimientoSeleccionado(r.ID);
                            setModalDesaplicado(true);
                          }}
                        >
                          Desaplicar
                        </button>
                        <button
                          type="button"
                          className="block w-full font-medium px-4 py-2 text-xs flex justify-start"
                          role="menuitem"
                          onClick={() => {
                            setIdMovimientoSeleccionado(r.ID);
                            setModalConfirmacionEliminar(true);
                          }}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        ))
      ) : datosPersona.APELLIDO !== undefined ? (
        <div>
          {/*           <div className="flex justify-between">
            <div>
              <h1 className="font-bold text-xl">Movimientos</h1>
              <h2 className="font-medium text-gray-400 mb-3">
                {datosPersona.TIPOPERSONA === "F"
                  ? datosPersona.NOMBRE + " " + datosPersona.APELLIDO
                  : datosPersona.OBSERVACIONES}
              </h2>
            </div>
            <button
              className="bg-blue-800 text-white px-12 h-8 mt-1"
              onClick={() => {
                setModalAltaMovimiento(true);
              }}
            >
              NUEVO
            </button>
          </div> */}
          <div className="border border-black p-4 items-center mt-10">
            <div className="flex flex-row text-center items-center justify-center">
              <h1 className="text-gray-500 font-bold text-xl">
                Esta persona no posee movimientos
              </h1>
            </div>
          </div>
        </div>
      ) : null}
      {!loadingMovimientos && datosPersona.APELLIDO !== undefined ? (
        <div className="border-2 border-l-0 border-r-0 border-dashed border-gray-400 text-right p-4 mt-2 ">
          <h1 className="text-2xl font-bold text-gray-500">Saldo de Cuenta</h1>
          <h1 className="text-3xl font-bold font-mono">
            {formatImporte(saldoCuenta)}
          </h1>
        </div>
      ) : null}
      {modalAltaMovimiento ? <ModalAltaMovimiento /> : null}
      {modalConfirmacion ? <ModalConfirmacion /> : null}
      {modalAplicadoMovimiento ? <ModalAplicadoMovimiento /> : null}
      {modalAplicarMultiple ? <ModalAplicarMultiple /> : null}
      {modalReaplicado ? <ModalReaplicar /> : null}
      {modalConfirmacionAplicado ? <ModalConfirmacionAplicado /> : null}
      {modalDesaplicado ? <ModalConfirmacionDesplicado /> : null}
      {modalConfirmacionReaplicado ? <ModalConfirmacionReaplicado /> : null}
      {modalConfirmacionEliminar ? <ModalConfirmacionEliminar /> : null}
    </div>
  );
}
export default Movimientos;
