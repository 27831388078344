import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import MovimientosContext from "../../context/movimientos/movimientosContext";
import BuscadorContext from "../../context/buscador/buscadorContext";
import axiosClient from "../../config/axios";

function ModalAplicarMultiple() {
  const authContext = useContext(AuthContext);
  const { header } = authContext;

  const movimientosContext = useContext(MovimientosContext);
  const {
    setModalAplicarMultiple,
    arrCabezas,
    setArrCabezas,
    arrCabezasAplicados,
    setArrCabezasAplicados,
    idMovimientoSeleccionado,
    setModalAplicadoMovimiento,
    setDatosFormularioAlta,
  } = movimientosContext;

  const buscarContext = useContext(BuscadorContext);
  const { datosPersona, formatDate, formatImporte } = buscarContext;

  const [loading, setLoading] = useState(true);
  const [importeTotal, setImporteTotal] = useState(0);
  const [validado, setValidado] = useState(false);
  const [validando, setValidando] = useState(false);
  const [movimientosDisponibles, setMovimientosDisponibles] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");

  useEffect(() => {
    getMovimientosCabeza();
  }, [arrCabezas]);

  const getMovimientosCabeza = async () => {
    if (
      arrCabezas.length === 0 &&
      Object.keys(arrCabezasAplicados).length === 0
    ) {
      setLoading(true);
      const data = {
        idPersona: datosPersona.ID,
        saldoCero: false,
      };
      await axiosClient
        .post("/get_movimientos_personas", data, header)
        .then((response) => {
          const filteredResponse = response.data.data.movimientos.filter(
            (entry) => entry.ID !== idMovimientoSeleccionado
          );
          if (filteredResponse.length === 0) {
            setMovimientosDisponibles(false);
          } else {
            const movimientoSeleccionado =
              response.data.data.movimientos.filter(
                (entry) => entry.ID === idMovimientoSeleccionado
              );
            setArrCabezasAplicados(movimientoSeleccionado);
            setArrCabezas(filteredResponse);
            setImporteTotal(movimientoSeleccionado[0].SALDO);
            setMovimientosDisponibles(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // aca falta patear por expiracion de sesion
        });
    }
  };

  const getFacturasCardinal = async () => {
    setValidando(true);
    setShowAlert(false);
    setTextAlert("");
    var error = false;
    var data = {
      idEmpresa: 2, // Por ahora va harcodeado como 2, porque es del CAER
      facturas: arrCabezasAplicados,
    };
    await axiosClient
      .post("/consultar_facturas", data, header)
      .then((response) => {
        for (var i = 0; i < arrCabezasAplicados.length; i++) {
          var facturaCoincidente = response.data.data.filter(
            (fCardinal) =>
              arrCabezasAplicados[i].ID === fCardinal.idReferencia.toString()
          )[0];
          if (
            parseFloat(arrCabezasAplicados[i].saldo) > facturaCoincidente.saldo
          ) {
            setTextAlert(
              "La factura número " +
                arrCabezasAplicados[i].NROCAB +
                " tiene un saldo mayor al disponible en Cardinal. Por favor, actualizá los movimientos."
            );
            error = true;
            break;
          }
        }
        if (!error) {
          setValidado(true);
        } else {
          setShowAlert(true);
          setValidado(false);
        }
        setValidando(false);
      })
      .catch((err) => {
        console.log(err);
        setTextAlert(
          "Ocurrió un error inesperado, por favor intentá más tarde"
        );
        setShowAlert(true);
        setValidando(false);
      });
  };

  const seleccionarMovimiento = (idMovimiento) => {
    const filteredCabezas = arrCabezas.filter(
      (cabeza) => cabeza.ID !== idMovimiento
    );
    const movimiento_a_agregar = arrCabezas.filter(
      (cabeza) => cabeza.ID === idMovimiento
    )[0];
    const newCabezasAplicadosArray = [...arrCabezasAplicados];
    newCabezasAplicadosArray.push(movimiento_a_agregar);
    const nuevoImporte = newCabezasAplicadosArray.reduce(
      (total, movimiento) => parseFloat(movimiento.SALDO) + parseFloat(total),
      0
    );
    console.log(newCabezasAplicadosArray);
    setImporteTotal(nuevoImporte);
    setArrCabezas(filteredCabezas);
    setArrCabezasAplicados(newCabezasAplicadosArray);
  };

  const desSeleccionarMovimiento = (idMovimiento) => {
    const filteredCabezasAplicadas = arrCabezasAplicados.filter(
      (cabeza) => cabeza.ID !== idMovimiento
    );
    const movimiento_a_remover = arrCabezasAplicados.filter(
      (cabeza) => cabeza.ID === idMovimiento
    )[0];
    const newCabezasArray = [...arrCabezas];
    newCabezasArray.push(movimiento_a_remover);
    const nuevoImporte = filteredCabezasAplicadas.reduce(
      (total, movimiento) => parseFloat(movimiento.SALDO) + parseFloat(total),
      0
    );
    setImporteTotal(nuevoImporte);
    setArrCabezasAplicados(filteredCabezasAplicadas);
    setArrCabezas(newCabezasArray);
  };

  // Aca se supone que se valida previamente con la API, pero todavia no esta eso en el back
  const validateFormulario = () => {
    getFacturasCardinal();
    /*     const importe = arrCabezasAplicados.reduce(
      (total, movimiento) => parseFloat(movimiento.SALDO) + parseFloat(total),
      0
    );
    if (importeTotal === importe) {
      setValidado(true);
      return true;
    } else {
      return false;
    } */
  };

  const abrirModalConfirmacion = () => {
    setDatosFormularioAlta({
      idPersona: datosPersona.ID,
      codAp: "FC",
      nroAp: "",
      fechaEmision: "",
      fechaVencimiento: "",
      letra: "",
      nroComprobante: "",
      observaciones: "",
      descripcion: "",
      arrCabezas: [
        {
          idCabeza: "",
          importe: importeTotal,
        },
      ],
    });
    setModalAplicadoMovimiento(true);
    setModalAplicarMultiple(false);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-between border-b-2 p-4 pb-0 border-gray-500">
            <div>
              <h1 className="font-bold text-xl">Aplicar Múltiple</h1>
              <h2 className="font-medium text-gray-400 mb-3">
                Movimientos disponibles para aplicar
              </h2>
            </div>
            <button
              onClick={() => {
                setModalAplicarMultiple(false);
                setArrCabezas([]);
                setArrCabezasAplicados({});
              }}
              className="bg-gray-200 border-2 border-gray-400 px-1 h-8 mt-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div>
            {loading ? (
              <div className="p-4 items-center mt-2 mb-2">
                <div className="flex flex-row text-center items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="text-gray-500 animate-spin h-10 w-10 mr-3"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <h1 className="text-gray-500 font-bold text-3xl">Cargando</h1>
                </div>
              </div>
            ) : movimientosDisponibles ? (
              <div className="p-4">
                {showAlert ? (
                  <div className="flex justify-between p-4 mb-6 bg-red-400 items-center">
                    <div className="flex flex-row">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 text-white mt-1 mr-3"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <h1 className="font-semibold text-medium text-white ">
                        {textAlert}
                      </h1>
                    </div>
                    <button
                      className="bg-red-400 border-2 border-white px-1 h-6"
                      onClick={() => {
                        setShowAlert(false);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                ) : null}

                {arrCabezas.map((c) => (
                  <div
                    key={c.ID}
                    className="lg:grid grid-cols-5 gap-4 border-b-2 border-gray-500 mb-6"
                  >
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">CÓDIGO</p>
                      <p className="text-sm font-mono">{c.CODCAB}</p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">NÚMERO</p>
                      <p className="text-sm font-mono">{c.NROCAB}</p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">
                        FECHA EMISIÓN
                      </p>
                      <p className="text-sm font-mono">
                        {formatDate(c.FECHAEMISION)}
                      </p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">SALDO</p>
                      <p className="text-sm font-mono">
                        {formatImporte(c.SALDO)}
                      </p>
                    </div>
                    <div className=" lg:m-0 mb-2 text-right">
                      <button
                        className={
                          validado
                            ? "bg-blue-600 py-1 px-1"
                            : "bg-blue-800 py-1 px-1"
                        }
                        disabled={validado}
                        onClick={() => seleccionarMovimiento(c.ID)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-white"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
                <div className="mt-20 mb-20">
                  <h1 className="font-bold text-xl mb-2">Seleccionados</h1>
                  {arrCabezasAplicados.map((a) => (
                    <div
                      key={a.ID}
                      className="lg:grid grid-cols-5 gap-4 border-b-2 border-gray-500 mb-6"
                    >
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          CÓDIGO
                        </p>
                        <p className="text-sm font-mono">{a.CODCAB}</p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          NÚMERO
                        </p>
                        <p className="text-sm font-mono">{a.NROCAB}</p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FECHA EMISIÓN
                        </p>
                        <p className="text-sm font-mono">
                          {formatDate(a.FECHAEMISION)}
                        </p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">SALDO</p>
                        <p className="text-sm font-mono">
                          {formatImporte(a.SALDO)}
                        </p>
                      </div>
                      <div className=" lg:m-0 mb-2 text-right">
                        <button
                          className={
                            a.ID !== idMovimientoSeleccionado
                              ? validado
                                ? "bg-red-300 py-1 px-1"
                                : "bg-red-600 py-1 px-1"
                              : "bg-red-300 py-1 px-1"
                          }
                          disabled={
                            a.ID === idMovimientoSeleccionado || validado
                          }
                          onClick={() => desSeleccionarMovimiento(a.ID)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 text-white"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="border-2 border-l-0 border-r-0 border-dashed border-gray-400 text-right p-4 ">
                  <h1 className="text-3xl font-medium text-gray-500 mb-1">
                    Importe Total
                  </h1>
                  <h1 className="text-3xl  font-mono">
                    {formatImporte(importeTotal)}
                  </h1>
                </div>
                <div className="bg-gray-200 flex justify-between pt-4">
                  <button
                    className={
                      validado
                        ? "bg-green-500 text-white px-10 py-2"
                        : validando
                        ? "bg-blue-400 cursor-not-allowed text-white px-10 py-2"
                        : "bg-blue-800 text-white px-10 py-2"
                    }
                    disabled={validando}
                    onClick={() => {
                      validado
                        ? abrirModalConfirmacion()
                        : validateFormulario();
                    }}
                  >
                    {validado ? (
                      "ACEPTAR"
                    ) : validando ? (
                      <div className="flex flex-row">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="text-white animate-spin h-5 w-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    ) : (
                      "VALIDAR"
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div className="p-4 items-center mt-2 mb-2">
                <div className="flex flex-row text-center items-center justify-center">
                  <h1 className="text-gray-500 font-bold text-2xl">
                    No hay movimientos disponibles para aplicar
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAplicarMultiple;
