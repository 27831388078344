import {
  LOGING_SUCCESS,
  LOGING_ERROR,
  GET_USER,
  LOG_OUT,
  SET_FECHA,
} from "../../types/index.js";

export default (state, action) => {
  switch (action.type) {
    case GET_USER:
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("fecha", action.payload.fecha);
      localStorage.setItem("hora", action.payload.hora);
      return {
        ...state,
        auth: true,
        token: localStorage.getItem("token"),
        username: localStorage.getItem("username"),
        fecha: localStorage.getItem("fecha"),
        hora: localStorage.getItem("hora"),
      };

    case LOGING_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("fecha", action.payload.fecha);
      localStorage.setItem("hora", action.payload.hora);
      localStorage.setItem("username", action.payload.username);
      return {
        ...state,
        auth: true,
        token: localStorage.getItem("token"),
        username: localStorage.getItem("username"),
        fecha: localStorage.getItem("fecha"),
        hora: localStorage.getItem("hora"),
      };
    case LOGING_ERROR:
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("fecha");
      localStorage.removeItem("hora");
      return {
        ...state,
        token: null,
        auth: false,
        username: null,
        fecha: null,
        hora: null,
      };
    case LOG_OUT:
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("name");
      localStorage.removeItem("fecha");
      localStorage.removeItem("hora");
      return {
        ...state,
        token: null,
        auth: false,
        username: null,
        fecha: null,
        hora: null,
      };
    case SET_FECHA:
      return {
        ...state,
        fecha: action.payload,
      };
    default:
      return state;
  }
};
