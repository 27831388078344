import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BuscadorContext from "../../context/buscador/buscadorContext";

function Resultados() {
  const buscadorContext = useContext(BuscadorContext);
  const {
    personasBuscadas,
    loadingBusqueda,
    getMovimientosPersona,
    setDatosPersona,
    setDatosFiltros,
    buscarPersonasAPI,
  } = buscadorContext;

  const [ready, setReady] = useState(false);

  let location = useLocation();

  useEffect(() => {
    const dniPersona = new URLSearchParams(location.search).get("dni");
    if (dniPersona !== "null" && dniPersona !== null && dniPersona !== "") {
      var data = {
        tipo: "F",
        dni: dniPersona,
      };
      buscarPersonasAPI(data);
    }
  }, []);

  useEffect(() => {
    const dniPersona = new URLSearchParams(location.search).get("dni");
    if (
      personasBuscadas.length > 0 &&
      dniPersona !== "null" &&
      dniPersona !== "" &&
      dniPersona !== null &&
      !ready
    ) {
      setReady(true);
      setDatosFiltros({ desde: "", hasta: "", saldoCero: false });
      setDatosPersona(personasBuscadas[0]);
      getMovimientosPersona(
        personasBuscadas[0].ID,
        personasBuscadas[0].NRO_DOCUMENTO,
        true
      );
    }
  }, [personasBuscadas]);

  return (
    <div className="mb-3">
      <h1 className="font-bold text-xl mb-3">Personas</h1>
      {loadingBusqueda ? (
        <h1 className="font-bold text-xl text-gray-500 mb-3 text-center">
          Cargando
        </h1>
      ) : null}
      {personasBuscadas.length > 0
        ? personasBuscadas.map((p) => (
            <button
              key={p.ID}
              className="border border-black p-2 w-full text-left mb-3"
              onClick={() => {
                setDatosFiltros({
                  desde: "",
                  hasta: "",
                  saldoCero: false,
                });
                setDatosPersona(p);
                getMovimientosPersona(p.ID, p.NRO_DOCUMENTO, true);
              }}
            >
              <p className="font-bold">
                {p.TIPOPERSONA === "F"
                  ? p.APELLIDO + " " + p.NOMBRE
                  : p.OBSERVACIONES}
              </p>
              <p className="font-mono">{p.NRO_DOCUMENTO}</p>
            </button>
          ))
        : null}
    </div>
  );
}
export default Resultados;
