import React, { useReducer, useContext, useState } from "react";
import authContext from "./authContext";
import authReducer from "./authReducer";
import axiosClient from "../../config/axios";

import AlertContext from "../alerts/alertContext";

import {
  LOGING_SUCCESS,
  LOGING_ERROR,
  GET_USER,
  LOG_OUT,
  SET_FECHA,
} from "../../types/index.js";

const AuthState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const initialState = {
    token: null,
    auth: false,
    username: null,
    name: null,
    fecha: null,
    hora: null,
    loginLoading: false,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [fechaInput, setFechaInput] = useState("");

  const header = {
    headers: {
      Authorization: "Bearer " + state.token,
    },
  };

  const loginUser = async (data) => {
    setLoadingLogin(true);
    try {
      const response = await axiosClient.post("login", data);
      dispatch({
        type: LOGING_SUCCESS,
        payload: response.data,
      });
      setLoadingLogin(false);
      setAlert("Ingreso Existoso", "green-300");
    } catch (error) {
      dispatch({
        type: LOGING_ERROR,
      });
      setLoadingLogin(false);
      setAlert(error.response.data.message, "red-300");
    }
  };

  const getUser = async () => {
    //header que chequea con el token de local storage.
    const data = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      const response = await axiosClient.get("v2/key/self", data);
      dispatch({
        type: GET_USER,
        payload: response.data.data,
      });
    } catch (error) {
      setAlert("La sesion ha expirado", "yellow-300");
      dispatch({
        type: LOGING_ERROR,
      });
    }
  };

  const logoutUser = () => {
    dispatch({
      type: LOG_OUT,
    });
  };

  const setFecha = (data) => {
    var date = new Date(data);
    setFechaInput(data);

    if (!isNaN(date.getTime())) {
      var day = date.getUTCDate().toString();
      var month = (date.getUTCMonth() + 1).toString();
      var fecha =
        date.getFullYear() +
        "/" +
        (month[1] ? month : "0" + month[0]) +
        "/" +
        (day[1] ? day : "0" + day[0]);
      dispatch({
        type: SET_FECHA,
        payload: fecha,
      });
    }
  };

  return (
    <authContext.Provider
      value={{
        token: state.token,
        auth: state.auth,
        username: state.username,
        fecha: state.fecha,
        hora: state.hora,
        header: header,
        loadingLogin: loadingLogin,
        loginUser,
        getUser,
        logoutUser,
        setFecha,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthState;
