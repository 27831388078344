import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";

function BaseTemplate(props) {
  return (
    <div>
      <Nav />
      <div>{props.children}</div>
      <Footer/>
    </div>
  );
}
export default BaseTemplate;
