import React from "react";

function Footer() {
  return (
    <div className="bg-gray-300 py-10 px-4">
      <p>Footer</p>
    </div>
  );
}
export default Footer;
