import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import MovimientosContext from "../../context/movimientos/movimientosContext";
import BuscadorContext from "../../context/buscador/buscadorContext";
import axiosClient from "../../config/axios";

function ModalAplicarMovimiento() {
  const authContext = useContext(AuthContext);
  const { header } = authContext;

  const movimientosContext = useContext(MovimientosContext);
  const {
    setModalAplicadoMovimiento,
    datosFormularioAlta,
    setDatosFormularioAlta,
    setModalConfirmacionAplicado,
    idMovimientoSeleccionado,
    arrCabezasAplicados,
    setArrCabezas,
    setArrCabezasAplicados,
    lastCodMovimiento,
  } = movimientosContext;

  const buscadorContext = useContext(BuscadorContext);
  const { datosPersona } = buscadorContext;

  const [loading, setLoading] = useState(true);
  const [codigosMovimiento, setCodigosMovimiento] = useState({});
  const [errorFormulario, setErrorFormulario] = useState(false);
  const [formularioValidado, setFormularioValidado] = useState(false);
  const [textoAlerta, setTextoAlerta] = useState("");
  const [usaFechaVencimiento, setUsaFechaVencimiento] = useState(false);
  const [opcionesLetras, setOpcionesLetras] = useState([]);

  useEffect(() => {
    getCodigosDeMovimiento();
  }, []);

  useEffect(() => {
    if (
      codigosMovimiento.length > 0 &&
      Object.keys(arrCabezasAplicados).length === 0
    ) {
      const codCabActual =
        lastCodMovimiento !== null ? lastCodMovimiento : "30";
      const codActual = codigosMovimiento.filter(
        (cod) => cod.Clave === codCabActual
      )[0];
      //const letra = codActual.tipo_letra[0];
      setDatosFormularioAlta({
        idPersona: datosPersona.ID,
        idEmpresa: 2,
        codAp: lastCodMovimiento !== null ? lastCodMovimiento : "30",
        nroAp: "",
        fechaEmision: "",
        fechaVencimiento: "",
        //letra: letra,
        nroComprobante: "",
        observaciones: "",
        descripcion: "",
        arrCabezas: [
          {
            idCabeza: idMovimientoSeleccionado,
            importe:
              datosFormularioAlta.arrCabezas !== undefined
                ? datosFormularioAlta.arrCabezas[0].importe
                : "",
          },
        ],
      });
    }
  }, [codigosMovimiento]);

  useEffect(() => {}, [opcionesLetras]);

  const getCodigosDeMovimiento = async () => {
    setLoading(true);
    axiosClient
      .get("get_tipos_movimientos", header)
      .then((response) => {
        setCodigosMovimiento(response.data.data);
        const codCabActual =
          lastCodMovimiento !== null ? lastCodMovimiento : "30";
        const codActual = response.data.data.filter(
          (cod) => cod.Clave === codCabActual
        );
        setOpcionesLetras(codActual[0].tipo_letra);
        setUsaFechaVencimiento(codActual[0].usa_fecha_vencimiento);
        const newDatosFormulario = {
          idPersona: datosPersona.ID,
          idEmpresa: 2,
          codAp: lastCodMovimiento !== null ? lastCodMovimiento : "30",
          nroAp: "",
          fechaEmision: "",
          fechaVencimiento: "",
          //letra: letra,
          nroComprobante: "",
          observaciones: "",
          descripcion: "",
          arrCabezas: [
            {
              idCabeza: idMovimientoSeleccionado,
              importe:
                datosFormularioAlta.arrCabezas !== undefined
                  ? datosFormularioAlta.arrCabezas[0].importe
                  : "",
            },
          ],
        };
        newDatosFormulario["nroAp"] = codActual[0][
          "siguienteSugerido"
        ].padStart(12, "0");
        setDatosFormularioAlta(newDatosFormulario);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateValue = (e) => {
    const newDatosFormulario = { ...datosFormularioAlta };
    if (e.target.name === "nroAp" && !isNaN(e.target.value)) {
      newDatosFormulario[e.target.name] = e.target.value;
      setDatosFormularioAlta(newDatosFormulario);
    } else {
      if (e.target.name !== "nroAp") {
        newDatosFormulario[e.target.name] = e.target.value;
        if (e.target.name === "codAp") {
          const codActual = codigosMovimiento.filter(
            (cod) => cod.Clave === e.target.value
          );
          setOpcionesLetras(codActual[0].tipo_letra);
          setUsaFechaVencimiento(codActual[0].usa_fecha_vencimiento);
          newDatosFormulario["nroAp"] = codActual[0][
            "siguienteSugerido"
          ].padStart(12, "0");
        }
        if (!usaFechaVencimiento && e.target.name === "fechaEmision") {
          newDatosFormulario["fechaVencimiento"] = e.target.value;
        }
        setDatosFormularioAlta(newDatosFormulario);
      }
    }
  };

  const updateImporte = (e) => {
    const newDatosFormulario = { ...datosFormularioAlta };
    const newDatosImporte = { ...datosFormularioAlta.arrCabezas[0] };
    newDatosImporte.importe = e.target.value;
    newDatosFormulario.arrCabezas[0] = newDatosImporte;
    setDatosFormularioAlta(newDatosFormulario);
  };

  const validateFormulario = () => {
    setErrorFormulario(false);
    for (const [key, value] of Object.entries(datosFormularioAlta)) {
      if (key !== "observaciones" && value === "") {
        setTextoAlerta("Por favor, completá todos los campos");
        setErrorFormulario(true);
        return false;
      }
    }
    if (
      parseFloat(datosFormularioAlta.nroAp) < 0 ||
      isNaN(datosFormularioAlta.nroAp)
    ) {
      setTextoAlerta("Por favor, revisá el número de movimiento");
      setErrorFormulario(true);
      return false;
    } else {
      if (datosFormularioAlta.nroComprobante < 0) {
        setTextoAlerta("Por favor, revisá el número de comprobante");
        setErrorFormulario(true);
        return false;
      } else {
        if (
          datosFormularioAlta.fechaVencimiento <
          datosFormularioAlta.fechaEmision
        ) {
          setTextoAlerta("Por favor, revisá las fechas");
          setErrorFormulario(true);
          return false;
        }
      }
    }
    if (!usaFechaVencimiento) {
      const newDatosFormulario = { ...datosFormularioAlta };
      newDatosFormulario["fechaVencimiento"] =
        newDatosFormulario["fechaEmision"];
      setDatosFormularioAlta(newDatosFormulario);
    }
    setFormularioValidado(true);
    return true;
  };

  const altaAPI = () => {
    if (validateFormulario()) {
      setModalAplicadoMovimiento(false);
      setModalConfirmacionAplicado(true);
    } else {
      setFormularioValidado(false);
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-between border-b-2 p-4 pb-0 border-gray-500">
            <div>
              <h1 className="font-bold text-xl">Aplicar Movimiento</h1>
              <h2 className="font-medium text-gray-400 mb-3">
                Formulario de carga
              </h2>
            </div>
            <button
              className="bg-gray-200 border-2 border-gray-400 px-1 h-8 mt-1"
              onClick={() => {
                {
                  if (Object.keys(arrCabezasAplicados).length > 0) {
                    setDatosFormularioAlta({});
                    setArrCabezas([]);
                    setArrCabezasAplicados({});
                    setModalAplicadoMovimiento(false);
                  } else {
                    setDatosFormularioAlta({});
                    setModalAplicadoMovimiento(false);
                  }
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>

          {loading ? (
            <div className="p-4 items-center mt-2 mb-2">
              <div className="flex flex-row text-center items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-gray-500 animate-spin h-10 w-10 mr-3"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clip-rule="evenodd"
                  />
                </svg>
                <h1 className="text-gray-500 font-bold text-3xl">Cargando</h1>
              </div>
            </div>
          ) : (
            <div>
              {errorFormulario ? (
                <div className="flex justify-between p-4 m-4 bg-red-400 items-center">
                  <div className="flex flex-row">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 text-white mt-1 mr-3"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h1 className="font-bold text-medium text-white mt-2">
                      {textoAlerta}
                    </h1>
                  </div>
                  <button
                    className="bg-red-400 border-2 border-white px-1 h-6"
                    onClick={() => {
                      setErrorFormulario(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              ) : null}
              <div className="flex flex-wrap lg:grid lg:grid-cols-2 lg:gap-1 p-4">
                <div className="p-2 pb-0 mb-0 w-full">
                  <label
                    className="font-mono font-bold"
                    htmlFor="cod_movimiento"
                  >
                    CÓDIGO DE MOVIMIENTO
                  </label>
                  <select
                    id="cod_movimiento"
                    name="codAp"
                    className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    onChange={updateValue}
                    value={datosFormularioAlta.codAp}
                  >
                    {codigosMovimiento.map((c) => (
                      <option key={c.Clave} value={c.Clave}>
                        {c.Clave + " - " + c.Descripcion}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="p-2 pb-0 mb-0 w-full">
                  <label
                    className="font-mono font-bold"
                    htmlFor="nro_movimiento"
                  >
                    NRO. DE MOVIMIENTO
                  </label>
                  <input
                    id="nro_movimiento"
                    name="nroAp"
                    className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="000000000000"
                    type="text"
                    maxLength={12}
                    value={datosFormularioAlta.nroAp}
                    onChange={updateValue}
                  ></input>
                </div>
                <div className="p-2 pb-0 mb-0 w-full">
                  <label
                    className="font-mono font-bold"
                    htmlFor="fecha_emision"
                  >
                    FECHA DE EMISIÓN
                  </label>
                  <input
                    id="fecha_emision"
                    name="fechaEmision"
                    className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    type="date"
                    onChange={updateValue}
                  ></input>
                </div>
                <div className="p-2 pb-0 mb-0 w-full">
                  <label
                    className="font-mono font-bold"
                    htmlFor="fecha_vencimiento"
                  >
                    FECHA DE VENCIMIENTO
                  </label>
                  <input
                    id="fecha_vencimiento"
                    name="fechaVencimiento"
                    className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    type="date"
                    onChange={updateValue}
                    value={
                      usaFechaVencimiento
                        ? datosFormularioAlta.fechaVencimiento
                        : datosFormularioAlta.fechaEmision
                    }
                    disabled={!usaFechaVencimiento}
                  ></input>
                </div>
                {/*                 <div className="p-2 pb-0 mb-0 w-full">
                  <label className="font-mono font-bold" htmlFor="letra">
                    LETRA
                  </label>
                  <select
                    className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    id="letra"
                    name="letra"
                    onChange={updateValue}
                  >
                    {opcionesLetras.map((l) => (
                      <option key={l} value={l}>
                        {l}
                      </option>
                    ))}
                  </select>
                </div> */}
                <div className="p-2 pb-0 mb-0 w-full">
                  <label className="font-mono font-bold" htmlFor="letra">
                    NRO. COMPROBANTE
                  </label>
                  <input
                    id="numero_comprobante"
                    name="nroComprobante"
                    className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="000000000000"
                    type="number"
                    min="0"
                    onChange={updateValue}
                  ></input>
                </div>
                <div className="p-2 pb-0 mb-0 w-full">
                  <label className="font-mono font-bold" htmlFor="importe">
                    IMPORTE
                  </label>
                  <input
                    id="importe"
                    name="total"
                    className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="$ XXXX"
                    type="number"
                    onChange={updateImporte}
                    disabled={Object.keys(arrCabezasAplicados).length > 0}
                    value={datosFormularioAlta.arrCabezas[0].importe}
                  ></input>
                </div>
                <div className="p-2 pb-0 mb-0 w-full col-span-2">
                  <label className="font-mono font-bold" htmlFor="tipo_dato">
                    DESCRIPCIÓN
                  </label>
                  <input
                    id="descripcion"
                    name="descripcion"
                    className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="DESCRIPCIÓN"
                    type="text"
                    onChange={updateValue}
                  ></input>
                </div>
                <div className="p-2 pb-0 mb-0 col-span-2 w-full">
                  <label className="font-mono font-bold" htmlFor="letra">
                    OBSERVACIONES
                  </label>
                  <input
                    id="observaciones"
                    name="observaciones"
                    className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="OBSERVACIÓN"
                    type="text"
                    onChange={updateValue}
                  ></input>
                </div>
              </div>
              <div className="bg-gray-200 flex justify-between p-2 pb-4 ml-4">
                <button
                  className={
                    formularioValidado
                      ? "bg-green-500 text-white px-10 py-2"
                      : "bg-blue-800 text-white px-10 py-2"
                  }
                  onClick={() => {
                    formularioValidado ? altaAPI() : validateFormulario();
                  }}
                >
                  {formularioValidado ? "ACEPTAR" : "VALIDAR"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalAplicarMovimiento;
