import React, { useReducer, useEffect, useState, useContext } from "react";
import axiosClient from "../../config/axios";
import movimientosContext from "./movimientosContext";
import movimientosReducer from "./movimientosReducer";
import AuthContext from "../auth/authContext";
import AlertContext from "../alerts/alertContext";
import BuscadorContext from "../buscador/buscadorContext";

const MovimientosState = (props) => {
  const initialState = {};

  const authContext = useContext(AuthContext);
  const { header, getUser } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const buscadorContext = useContext(BuscadorContext);
  const { getMovimientosPersona, datosPersona, resetStateBuscador } =
    buscadorContext;

  const [state, dispatch] = useReducer(movimientosReducer, initialState);

  const [mostrarRecibosAplicados, setMostrarRecibosAplicados] = useState({});
  const [recibosAplicados, setRecibosAplicados] = useState({});
  const [esAplicado, setEsAplicado] = useState(false);
  const [lastCodMovimiento, setLastCodMovimiento] = useState(null);

  //Alta de movimiento
  const [modalAltaMovimiento, setModalAltaMovimiento] = useState(false);
  const [modalConfirmacion, setModalConfirmacion] = useState(false);
  const [datosFormularioAlta, setDatosFormularioAlta] = useState({});

  //Aplicado de movimiento
  const [modalAplicadoMovimiento, setModalAplicadoMovimiento] = useState(false);
  const [modalAplicarMultiple, setModalAplicarMultiple] = useState(false);
  const [modalConfirmacionAplicado, setModalConfirmacionAplicado] =
    useState(false);
  const [
    modalConfirmacionAplicadoMultiple,
    setModalConfirmacionAplicadoMultiple,
  ] = useState(false);
  const [arrCabezas, setArrCabezas] = useState([]);
  const [arrCabezasAplicados, setArrCabezasAplicados] = useState({});
  const [loadingAplicado, setLoadingAplicado] = useState(false);

  //Reaplicado de movimiento
  const [modalReaplicado, setModalReaplicado] = useState(false);
  const [modalConfirmacionReaplicado, setModalConfirmacionReaplicado] =
    useState(false);

  const [modalDesaplicado, setModalDesaplicado] = useState(false);

  //Eliminado de movimiento
  const [modalConfirmacionEliminar, setModalConfirmacionEliminar] =
    useState(false);
  const [idMovimientoSeleccionado, setIdMovimientoSeleccionado] =
    useState(null);
  const [loadingEliminado, setLoadingEliminado] = useState(false);

  useEffect(() => {}, []);

  const darDeAltaMovimiento = async () => {
    var payload = {
      codCab: datosFormularioAlta.codCab,
      numero: datosFormularioAlta.nroCab,
      codigo: datosFormularioAlta.nroCab,
      empresaId: 2,
      fecha: datosFormularioAlta.fechaEmision,
      fechaVencimiento: datosFormularioAlta.fechaVencimiento,
      fechaVencimiento2: new Date().getFullYear() + "-12-31",
      importe: datosFormularioAlta.importe,
      importe2: datosFormularioAlta.importe2,
      descripcion: datosFormularioAlta.descripcion,
      idPersona: datosPersona.ID,
      domicilio: "",
      nroSocio: datosPersona.NROSOCIO,
      idReferencia: "",
      periodo:
        datosFormularioAlta["periodo-year"] +
        "-" +
        datosFormularioAlta["periodo-month"],
    };

    await axiosClient
      .post("/add_movimiento_cabeza", payload, header)
      .then((response) => {
        getMovimientosPersona(datosPersona.ID);
        setDatosFormularioAlta({});
        setModalConfirmacion(false);
        setLastCodMovimiento(datosFormularioAlta.codCab);
        setAlert("El movimiento se dió de alta exitosamente", "green-300");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          resetStateMovimientos();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          console.log(err);
        }
      });
  };

  const aplicarMovimiento = async () => {
    setLoadingAplicado(true);
    await axiosClient
      .post("/add_movimiento_aplicado", datosFormularioAlta, header)
      .then((response) => {
        getMovimientosPersona(datosPersona.ID);
        setMostrarRecibosAplicados({});
        setRecibosAplicados({});
        setDatosFormularioAlta({});
        setIdMovimientoSeleccionado(null);
        setModalConfirmacionAplicado(false);
        setLoadingAplicado(false);
        setLastCodMovimiento(datosFormularioAlta.codAp);
        setAlert("El movimiento se aplicó correctamente", "green-300");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          resetStateMovimientos();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          setLoadingAplicado(false);
          console.log(err);
        }
      });
  };

  const aplicarMultiplesMovimientos = async () => {
    setLoadingAplicado(true);
    const aplicadosAEnviar = [];
    arrCabezasAplicados.forEach((cabeza) =>
      aplicadosAEnviar.push({ idCabeza: cabeza.ID, importe: cabeza.SALDO })
    );
    const newDatosFormulario = { ...datosFormularioAlta };
    newDatosFormulario.arrCabezas = aplicadosAEnviar;
    await axiosClient
      .post("/add_movimiento_aplicado", newDatosFormulario, header)
      .then((response) => {
        getMovimientosPersona(newDatosFormulario.idPersona);
        setMostrarRecibosAplicados({});
        setRecibosAplicados({});
        setDatosFormularioAlta({});
        setArrCabezas([]);
        setArrCabezasAplicados({});
        setIdMovimientoSeleccionado(null);
        setModalConfirmacionAplicado(false);
        setLoadingAplicado(false);
        setLastCodMovimiento(datosFormularioAlta.codAp);
        setAlert("Los movimientos se aplicaron correctamente", "green-300");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          resetStateMovimientos();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          setLoadingAplicado(false);
          console.log(err);
        }
      });

    /*     await axiosClient
    .post("") */
  };

  const reaplicarMovimientos = async () => {
    setLoadingAplicado(true);
    await axiosClient
      .post("add_replicar_movimiento", datosFormularioAlta, header)
      .then((response) => {
        getMovimientosPersona(datosPersona.ID);
        setArrCabezas([]);
        setArrCabezasAplicados({});
        setDatosFormularioAlta({});
        setModalConfirmacionReaplicado(false);
        setLoadingAplicado(false);
        setAlert("Los movimientos se reaplicaron correctamente", "green-300");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          resetStateMovimientos();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          setLoadingAplicado(false);
          console.log(err);
        }
      });
  };

  const desaplicarMovimiento = async () => {
    setLoadingAplicado(true);
    const payload = {
      idPersona: datosPersona.ID,
      idMovimiento: idMovimientoSeleccionado,
    };
    await axiosClient
      .post("add_desaplicar_movimiento", payload, header)
      .then((response) => {
        getMovimientosPersona(datosPersona.ID);
        setIdMovimientoSeleccionado(null);
        setModalDesaplicado(false);
        setAlert("El movimiento se desaplicó correctamente", "green-300");
        setLoadingAplicado(false);
        console.log(response);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          resetStateMovimientos();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          setLoadingAplicado(false);
          console.log(err);
        }
      });
  };

  const eliminarMovimiento = async () => {
    setLoadingEliminado(true);
    const data = {
      idPersona: datosPersona.ID,
      idMovimiento: idMovimientoSeleccionado,
    };
    await axiosClient
      .post("/eliminar_movimiento", data, header)
      .then((response) => {
        setLoadingEliminado(false);
        getMovimientosPersona(datosPersona.ID);
        setIdMovimientoSeleccionado(null);
        setModalConfirmacionEliminar(false);
        setAlert("El movimiento se eliminó exitosamente", "green-300");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          resetStateMovimientos();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          setLoadingEliminado(false);
          console.log(err);
        }
      });
  };

  const resetStateMovimientos = () => {
    setMostrarRecibosAplicados({});
    setRecibosAplicados({});
    setEsAplicado(false);
    setModalAltaMovimiento(false);
    setModalConfirmacion(false);
    setDatosFormularioAlta({});
    setModalAplicadoMovimiento(false);
    setModalAplicarMultiple(false);
    setModalConfirmacionAplicado(false);
    setModalConfirmacionAplicadoMultiple(false);
    setArrCabezas([]);
    setArrCabezasAplicados({});
    setLoadingAplicado(false);
    setModalReaplicado(false);
    setModalConfirmacionReaplicado(false);
    setModalDesaplicado(false);
    setModalConfirmacionEliminar(false);
    setIdMovimientoSeleccionado(null);
    setLoadingEliminado(false);
  };

  return (
    <movimientosContext.Provider
      value={{
        modalAltaMovimiento,
        setModalAltaMovimiento,
        modalConfirmacion,
        setModalConfirmacion,
        datosFormularioAlta,
        setDatosFormularioAlta,
        darDeAltaMovimiento,
        eliminarMovimiento,
        modalConfirmacionEliminar,
        setModalConfirmacionEliminar,
        idMovimientoSeleccionado,
        setIdMovimientoSeleccionado,
        loadingEliminado,
        modalAplicadoMovimiento,
        setModalAplicadoMovimiento,
        arrCabezas,
        setArrCabezas,
        arrCabezasAplicados,
        setArrCabezasAplicados,
        modalConfirmacionAplicado,
        setModalConfirmacionAplicado,
        aplicarMovimiento,
        loadingAplicado,
        setLoadingAplicado,
        mostrarRecibosAplicados,
        setMostrarRecibosAplicados,
        recibosAplicados,
        setRecibosAplicados,
        modalAplicarMultiple,
        setModalAplicarMultiple,
        modalConfirmacionAplicadoMultiple,
        setModalConfirmacionAplicadoMultiple,
        aplicarMultiplesMovimientos,
        modalReaplicado,
        setModalReaplicado,
        reaplicarMovimientos,
        modalConfirmacionReaplicado,
        setModalConfirmacion,
        setModalConfirmacionReaplicado,
        modalDesaplicado,
        setModalDesaplicado,
        desaplicarMovimiento,
        esAplicado,
        setEsAplicado,
        resetStateMovimientos,
        lastCodMovimiento,
        setLastCodMovimiento,
      }}
    >
      {props.children}
    </movimientosContext.Provider>
  );
};

export default MovimientosState;
