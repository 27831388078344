import React, { useContext, useState, useEffect } from "react";
import BuscadorContext from "../../context/buscador/buscadorContext";

function Buscador() {
  const buscadorContext = useContext(BuscadorContext);
  const { buscarPersonasAPI, loadingBusqueda } = buscadorContext;

  const [tipoPersona, setTipoPersona] = useState("F");
  const [tipoDato, setTipoDato] = useState("dni");
  const [tipoDatoMostrado, setTipoDatoMostrado] = useState("DNI");
  const [datoABuscar, setDatoABuscar] = useState("");

  useEffect(() => {
    var selectDato = document.getElementById("tipo_dato");
    setTipoDato(selectDato.value);
    setTipoDatoMostrado(selectDato.options[selectDato.selectedIndex].text);
  }, [tipoPersona]);

  const getSelectValue = (e) => {
    setTipoPersona(e.target.value);
    setDatoABuscar("");
  };

  const getDataSelectValue = (e) => {
    setTipoDato(e.target.value);
    setTipoDatoMostrado(e.target.options[e.target.selectedIndex].text);
    setDatoABuscar("");
  };

  const updateInput = (e) => {
    setDatoABuscar(e.target.value);
  };

  const buscarPersona = (e) => {
    e.preventDefault();

    var data = {
      tipo: tipoPersona,
      [tipoDato]: datoABuscar,
    };

    buscarPersonasAPI(data);
  };

  return (
    <div className="mb-3 mt-3">
      <label className="font-mono font-bold" htmlFor="tipo_persona">
        TIPO
      </label>
      <form id="form_busqueda">
        <select
          id="tipo_persona"
          name="tipo"
          className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          onChange={getSelectValue}
        >
          <option value="F">Persona Física</option>
          <option value="J">Persona Jurídica</option>
        </select>
        <label className="font-mono font-bold" htmlFor="tipo_dato">
          BUSCAR POR
        </label>
        <select
          id="tipo_dato"
          name="tipo_dato"
          className="mt-1 block w-full text-gray-600 h-10 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          onChange={getDataSelectValue}
        >
          <option value={tipoPersona === "F" ? "dni" : "cuit"}>
            {tipoPersona === "F" ? "DNI" : "CUIT"}
          </option>
          <option value={tipoPersona === "F" ? "ayn" : "razonSocial"}>
            {tipoPersona === "F" ? "Nombre y Apellido" : "Razón Social"}
          </option>
        </select>
        <label className="font-mono font-bold">{tipoDatoMostrado}</label>
        <input
          id="input_dato"
          name={tipoDato}
          value={datoABuscar}
          onChange={updateInput}
          placeholder={tipoDatoMostrado}
          className="mt-1 block w-full h-10 text-gray-600 bg-gray-200 border border-black p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          disabled={loadingBusqueda}
        ></input>
        <button
          type="submit"
          className={
            datoABuscar === ""
              ? "cursor-not-allowed mt-3 bg-gray-400 py-2 text-center px-7 text-white leading-tight font-sans"
              : loadingBusqueda
              ? "cursor-not-allowed mt-3 bg-blue-400 py-2 text-center px-12 text-white font-sans"
              : "mt-3 bg-blue-800 py-2 text-center px-7 text-white leading-tight font-sans"
          }
          onClick={buscarPersona}
          disabled={datoABuscar === "" || loadingBusqueda}
        >
          {loadingBusqueda ? (
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-white animate-spin h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          ) : (
            "BUSCAR"
          )}
        </button>
      </form>
    </div>
  );
}
export default Buscador;
