import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../context/auth/authContext";

import { useHistory } from "react-router-dom";

import Buscador from "./Personas/Buscador.js";
import Resultados from "./Personas/Resultados";
import Movimientos from "./Personas/Movimientos";

function Home() {
  const authContext = useContext(AuthContext);
  const { auth, getUser } = authContext;
  let history = useHistory();

  const [abrirMenu, setAbrirMenu] = useState(true);

  useEffect(() => {
    getUser();
    if (!auth) {
      history.push("/login");
    }
  }, [auth]);

  return (
    <div className={abrirMenu ? "xl:grid xl:grid-cols-4 xl:gap-0" : "xl:flex"}>
      <div
        className={
          abrirMenu
            ? "col-span-1 border border-gray-400 xl:border-l-0 xl:border-t-0 xl:border-b-0"
            : "col-span-1 border border-gray-400 xl:border-l-0 xl:border-t-0 xl:border-b-0"
        }
      >
        <div
          className={abrirMenu ? "px-4 py-4" : "px-4 py-4 flex justify-center"}
        >
          <button onClick={() => setAbrirMenu(!abrirMenu)}>
            {abrirMenu ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
            )}
          </button>
          {abrirMenu ? <Buscador></Buscador> : null}
        </div>

        <div className="border border-gray-400 border-b-0 border-l-0 border-r-0 px-4 py-4">
          {abrirMenu ? <Resultados></Resultados> : null}
        </div>
      </div>
      <div
        className={
          abrirMenu
            ? "col-span-3 px-4 py-4 min-h-screen border border-gray-400 xl:border-0"
            : "px-4 py-4 min-h-screen border border-gray-400 xl:border-0 w-full"
        }
      >
        <Movimientos></Movimientos>
      </div>
    </div>
  );
}
export default Home;
