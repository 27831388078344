import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth/authContext";
import BuscadorContext from "../context/buscador/buscadorContext";
import MovimientosContext from "../context/movimientos/movimientosContext";

import frontiniLogo from "../assets/img/frontini-logo-single.svg";

function Nav() {
  const authContext = useContext(AuthContext);
  const { auth, logoutUser, username } = authContext;
  const buscadorContext = useContext(BuscadorContext);
  const { resetStateBuscador } = buscadorContext;
  const movimientosContext = useContext(MovimientosContext);
  const { resetStateMovimientos } = movimientosContext;

  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const menuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const userMenuToggle = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  const cerrarSesion = () => {
    resetStateBuscador();
    resetStateMovimientos();
    logoutUser();
  };

  return (
    <nav className="bg-gray-200 border border-t-0 border-l-0 border-r-0 border-gray-400">
      <div className="mx-auto">
        <div className="relative flex items-center justify-between">
          <div className=" flex items-stretch justify-start">
            <div className="flex-shrink-0 flex items-center">
              <div className="border border-gray-400 border-t-0 border-l-0 border-b-0 px-4 py-3">
                <img src={frontiniLogo} className="h-9" alt="frontini logo" />
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex flex-1 items-center justify-center px-4 py-3">
            <div className="relative">
              <div>
                <button
                  type="button"
                  onClick={userMenuToggle}
                  className="flex items-center justify-center bg-mainBlueLight text-sm font-medium text-white focus:outline-none"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <div className="text-right">
                    <p className="font-bold text-gray-700 text-sm">
                      {username}
                    </p>
                    <p className="font-medium font-mono text-sm text-gray-400">
                      Ajustes
                    </p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="text-blue-600 h-7 w-7 ml-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={
                  userMenuOpen
                    ? "transition ease-out duration-100 transform opacity-100 scale-100"
                    : "transition ease-in duration-75 transform opacity-0 scale-0"
                }
              >
                <div
                  className="origin-top-right absolute right-0 mt-2 w-48 border border-gray-400 py-1 bg-gray-200"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <button
                    type="button"
                    onClick={cerrarSesion}
                    className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                    role="menuitem"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5 mr-4 text-blue-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    Desconectar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
