import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import AuthContext from "../context/auth/authContext";

function LoginJWT() {
  let location = useLocation();
  const JWT = new URLSearchParams(location.search).get("jwt");
  if (JWT === "" || JWT === null) {
    window.location.href = "/";
  }
  localStorage.setItem("token", JWT);
  const dniPersona = new URLSearchParams(location.search).get("dniPersona");
  localStorage.setItem("dniPersona", dniPersona);
  if (dniPersona === "" || dniPersona === null) {
    window.location.href = "/";
  }
  const nombrePersona = new URLSearchParams(location.search).get(
    "nombrePersona"
  );
  if (nombrePersona === "" || nombrePersona === null) {
    window.location.href = "/";
  }
  const authContext = useContext(AuthContext);
  const { auth, getUser } = authContext;

  useEffect(() => {
    getUser();
    if (auth) {
      window.location.href = `/`;
    }
  }, [auth]);

  return (
    <div className="border-2 flex flex-col justify-center items-center border-gray-700 p-4 m-4 mt-20 py-20 rounded">
      <div className="bg-white rounded-xl p-4 m-4 text-center">
        <h1 className="text-3xl">
          Te estamos redirigiendo a la Cuenta Corriente de
        </h1>
        <p className="text-3xl italic mt-1">{nombrePersona}</p>
      </div>
      <div className="flex flex-col text-center flex justify-center items-center">
        <p className="mt-4 text-xl">Aguardá unos momentos por favor</p>
        <svg
          className="animate-spin mt-2 h-12 w-12 text-mainBlue"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default LoginJWT;
