import React, { useContext, useState, useEffect } from "react";
import MovimientosContext from "../../context/movimientos/movimientosContext";

function ModalConfirmacion() {
  const movimientosContext = useContext(MovimientosContext);
  const {
    eliminarMovimiento,
    setModalConfirmacionEliminar,
    loadingEliminado,
  } = movimientosContext;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-gray-200 border-b-2 border-gray-500 px-4 my-2 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 mb-1 text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div className="">
              <h2 className="font-bold  font-sans text-3xl text-center">
                Atención
              </h2>
              <p className="text-lg text-center text-gray-600">
                Estás a punto de <b>eliminar</b> este movimiento
              </p>
              <br></br>
              <p className="text-lg text-center text-gray-600">
                ¿Está seguro/a de querer continuar?
              </p>
            </div>
          </div>
          <div className="bg-gray-200 flex justify-between p-2 pb-4 ml-4 mr-4">
            <button
              className={
                loadingEliminado
                  ? "bg-green-400 p-2 px-11 text-white"
                  : "bg-green-400 p-2 px-5 text-white"
              }
              onClick={eliminarMovimiento}
              disabled={loadingEliminado}
            >
              {loadingEliminado ? (
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="text-white animate-spin h-5 w-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              ) : (
                "ACEPTAR"
              )}
            </button>
            <button
              className="bg-red-400 p-2 px-5 text-white"
              onClick={() => {
                setModalConfirmacionEliminar(false);
              }}
              disabled={loadingEliminado}
            >
              CANCELAR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalConfirmacion;
