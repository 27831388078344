import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import MovimientosContext from "../../context/movimientos/movimientosContext";
import BuscadorContext from "../../context/buscador/buscadorContext";
import axiosClient from "../../config/axios";

function ModalReaplicar() {
  const authContext = useContext(AuthContext);
  const { header } = authContext;

  const movimientosContext = useContext(MovimientosContext);
  const {
    arrCabezas,
    setArrCabezas,
    arrCabezasAplicados,
    setArrCabezasAplicados,
    idMovimientoSeleccionado,
    setDatosFormularioAlta,
    setModalReaplicado,
    setModalConfirmacionReaplicado,
  } = movimientosContext;

  const buscarContext = useContext(BuscadorContext);
  const {
    datosPersona,
    formatDate,
    formatImporte,
    saldoCuenta,
  } = buscarContext;

  const [loading, setLoading] = useState(true);
  const [monto, setMonto] = useState(0);
  const [validado, setValidado] = useState(false);
  const [arrCabezasOriginal, setArrCabezasOriginal] = useState([]);
  const [saldosModificados, setSaldosModificados] = useState({});
  const [montoMayorASaldo, setMontoMayorASaldo] = useState(false);
  const [movimientosDisponibles, setMovimientosDisponibles] = useState(true);

  useEffect(() => {
    if (movimientosDisponibles) {
      getMovimientosCabeza();
    }
  }, [arrCabezas]);

  useEffect(() => {
    chequearMonto();
  }, [monto]);

  const getMovimientosCabeza = async () => {
    if (
      arrCabezas.length === 0 &&
      Object.keys(arrCabezasAplicados).length === 0
    ) {
      setLoading(true);
      const data = {
        idPersona: datosPersona.ID,
        saldoCero: false,
      };
      await axiosClient
        .post("/get_movimientos_personas", data, header)
        .then((response) => {
          const filteredResponse = response.data.data.movimientos.filter(
            (entry) => entry.ID !== idMovimientoSeleccionado
          );
          if (filteredResponse.length === 0) {
            setMovimientosDisponibles(false);
            setLoading(false);
          } else {
            setArrCabezasOriginal(response.data.data.movimientos);
            setArrCabezasAplicados([]);
            setArrCabezas(filteredResponse);
            //setMonto(movimientoSeleccionado[0].SALDO);
            setMovimientosDisponibles(true);
            setLoading(false);
          }
        });
    }
  };

  const seleccionarMovimiento = (idMovimiento) => {
    const filteredCabezas = arrCabezas.filter(
      (cabeza) => cabeza.ID !== idMovimiento
    );
    const movimiento_a_agregar = arrCabezas.filter(
      (cabeza) => cabeza.ID === idMovimiento
    )[0];
    const newCabezasAplicadosArray = [...arrCabezasAplicados];
    newCabezasAplicadosArray.push(movimiento_a_agregar);
    const newSaldosModificados = { ...saldosModificados };
    console.log(movimiento_a_agregar.SALDO);
    newSaldosModificados[movimiento_a_agregar.ID] = parseFloat(
      movimiento_a_agregar.SALDO
    ).toFixed(2);
    var nuevoImporte = 0;
    for (const [key, value] of Object.entries(newSaldosModificados)) {
      if (value === "") {
        nuevoImporte = nuevoImporte + 0;
      } else {
        nuevoImporte = nuevoImporte + parseFloat(value);
      }
    }
    setSaldosModificados(newSaldosModificados);
    setMonto(nuevoImporte);
    setArrCabezas(filteredCabezas);
    setArrCabezasAplicados(newCabezasAplicadosArray);
  };

  const desSeleccionarMovimiento = (idMovimiento) => {
    const filteredCabezasAplicadas = arrCabezasAplicados.filter(
      (cabeza) => cabeza.ID !== idMovimiento
    );
    const movimiento_a_remover = arrCabezasOriginal.filter(
      (cabeza) => cabeza.ID === idMovimiento
    )[0];
    const newCabezasArray = [...arrCabezas];
    newCabezasArray.push(movimiento_a_remover);
    const newSaldosModificados = { ...saldosModificados };
    delete newSaldosModificados[movimiento_a_remover.ID];
    var nuevoImporte = 0;
    for (const [key, value] of Object.entries(newSaldosModificados)) {
      if (value === "") {
        nuevoImporte = nuevoImporte + 0;
      } else {
        nuevoImporte = nuevoImporte + parseFloat(value);
      }
    }
    setSaldosModificados(newSaldosModificados);
    setMonto(nuevoImporte);
    setArrCabezasAplicados(filteredCabezasAplicadas);
    setArrCabezas(newCabezasArray);
  };

  const updateSaldo = (e, idMovimiento) => {
    const newSaldosModificados = { ...saldosModificados };
    if (e.target.value.includes(".") || e.target.value.includes(",")) {
      const value = e.target.value;
      const arr = value.split(".");
      if (arr.length <= 2 && arr[1].length <= 2) {
        newSaldosModificados[idMovimiento] = e.target.value;
      }
    } else {
      newSaldosModificados[idMovimiento] = e.target.value;
    }
    var nuevoMonto = 0;
    for (const [key, value] of Object.entries(newSaldosModificados)) {
      if (value === "") {
        nuevoMonto = nuevoMonto + 0;
      } else {
        nuevoMonto = nuevoMonto + parseFloat(value);
      }
    }
    setMonto(nuevoMonto);
    setSaldosModificados(newSaldosModificados);
  };

  const chequearMonto = () => {
    if (parseFloat(monto) > parseFloat(saldoCuenta)) {
      setMontoMayorASaldo(true);
    } else {
      setMontoMayorASaldo(false);
    }
  };

  // Aca se supone que se valida previamente con la API, pero todavia no esta eso en el back
  const validateFormulario = () => {
    /*     const importe = arrCabezasAplicados.reduce(
      (total, movimiento) => parseFloat(movimiento.SALDO) + parseFloat(total),
      0
    );
    if (monto === importe) {
      setValidado(true);
      return true;
    } else {
      return false;
    } */
    setValidado(true);
  };

  const abrirModalConfirmacion = () => {
    const payload = [];
    for (const [key, value] of Object.entries(saldosModificados)) {
      payload.push({ idCabeza: key, importe: value });
    }
    setDatosFormularioAlta({
      idPersona: datosPersona.ID,
      idMovimiento: idMovimientoSeleccionado,
      arrCabezas: payload,
    });
    setModalReaplicado(false);
    setModalConfirmacionReaplicado(true);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-between border-b-2 p-4 pb-0 border-gray-500">
            <div>
              <h1 className="font-bold text-xl">Reaplicar</h1>
              <h2 className="font-medium text-gray-400 mb-3">
                Movimientos disponibles para reaplicar
              </h2>
            </div>
            <button
              onClick={() => {
                setModalReaplicado(false);
                setArrCabezas([]);
                setArrCabezasAplicados({});
              }}
              className="bg-gray-200 border-2 border-gray-400 px-1 h-8 mt-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div>
            {loading ? (
              <div className="p-4 items-center mt-2 mb-2">
                <div className="flex flex-row text-center items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="text-gray-500 animate-spin h-10 w-10 mr-3"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <h1 className="text-gray-500 font-bold text-3xl">Cargando</h1>
                </div>
              </div>
            ) : movimientosDisponibles ? (
              <div className="p-4">
                {montoMayorASaldo ? (
                  <div className="flex justify-between p-4 bg-yellow-400 items-center mb-4">
                    <div className="flex flex-row">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 text-white mt-1 mr-3"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <h1 className="font-bold text-medium text-white mt-2">
                        Advertencia: El monto no debería superar el saldo
                      </h1>
                    </div>
                  </div>
                ) : null}
                {arrCabezas.map((c) => (
                  <div
                    key={c.ID}
                    className="lg:grid grid-cols-5 gap-4 border-b-2 border-gray-500 mb-6"
                  >
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">CÓDIGO</p>
                      <p className="text-sm font-mono">{c.CODCAB}</p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">NÚMERO</p>
                      <p className="text-sm font-mono">{c.NROCAB}</p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">
                        FECHA EMISIÓN
                      </p>
                      <p className="text-sm font-mono">
                        {formatDate(c.FECHAEMISION)}
                      </p>
                    </div>
                    <div className=" lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">SALDO</p>
                      <p className="text-sm font-mono">
                        {formatImporte(c.SALDO)}
                      </p>
                    </div>
                    <div className=" lg:mt-1 text-right">
                      <button
                        className={
                          validado
                            ? "bg-blue-600 py-1 px-1"
                            : "bg-blue-800 py-1 px-1"
                        }
                        disabled={validado}
                        onClick={() => seleccionarMovimiento(c.ID)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-white"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
                <div className="mt-10 mb-20">
                  <h1 className="font-bold text-xl mb-2">Seleccionados</h1>
                  {arrCabezasAplicados.map((a) => (
                    <div
                      key={a.ID}
                      className="lg:grid grid-cols-6 gap-4 border-b-2 border-gray-500 mb-6"
                    >
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          CÓDIGO
                        </p>
                        <p className="text-sm font-mono mt-1">{a.CODCAB}</p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          NÚMERO
                        </p>
                        <p className="text-sm font-mono mt-1">{a.NROCAB}</p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FECHA EMISIÓN
                        </p>
                        <p className="text-sm font-mono mt-1">
                          {formatDate(a.FECHAEMISION)}
                        </p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">SALDO</p>
                        <p className="text-sm font-mono mt-1">
                          {formatImporte(a.SALDO)}
                        </p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          IMPORTE
                        </p>
                        <input
                          className="mb-1 bg-gray-200 border-2 border-gray-500 text-sm font-mono"
                          type="number"
                          value={saldosModificados[a.ID]}
                          onChange={(e) => updateSaldo(e, a.ID)}
                          disabled={validado}
                        ></input>
                      </div>
                      <div className="lg:mt-1 text-right">
                        <button
                          className={
                            a.ID !== idMovimientoSeleccionado
                              ? validado
                                ? "bg-red-300 py-1 px-1 mt-2"
                                : "bg-red-600 py-1 px-1 mt-2"
                              : "bg-red-300 py-1 px-1 mt-2"
                          }
                          disabled={validado}
                          onClick={() => desSeleccionarMovimiento(a.ID)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 text-white"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="border-2 border-l-0 border-r-0 mb-4 border-dashed border-gray-400 text-right p-4 ">
                  <h1 className="text-3xl font-medium text-gray-500 mb-1">
                    Monto
                  </h1>
                  <h1 className="text-3xl  font-mono">
                    {formatImporte(monto)}
                  </h1>
                </div>
                <div className="border-2 border-l-0 border-r-0  border-dashed border-gray-400 text-right p-4 ">
                  <h1 className="text-3xl font-medium text-gray-500 mb-1">
                    Saldo Cuenta
                  </h1>
                  <h1 className="text-3xl  font-mono">
                    {formatImporte(saldoCuenta)}
                  </h1>
                </div>
                <div className="bg-gray-200 flex justify-between pt-4">
                  <button
                    className={
                      validado
                        ? "bg-green-500 text-white px-10 py-2"
                        : "bg-blue-800 text-white px-10 py-2"
                    }
                    onClick={() => {
                      validado
                        ? abrirModalConfirmacion()
                        : validateFormulario();
                    }}
                  >
                    {validado ? "ACEPTAR" : "VALIDAR"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="p-4 items-center mt-2 mb-2">
                <div className="flex flex-row text-center items-center justify-center">
                  <h1 className="text-gray-500 font-bold text-2xl">
                    No hay movimientos disponibles para reaplicar
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalReaplicar;
