import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./components/Login";
import Home from "./components/Home";
import BaseTemplate from "./components/BaseTemplate";
import LoginJWT from "./components/LoginJWT";
import Alert from "./components/Alert";

import AlertState from "./context/alerts/alertState";
import AuthState from "./context/auth/authState";
import BuscadorState from "./context/buscador/buscadorState";
import MovimientosState from "./context/movimientos/movimientosState";

function App() {
  return (
    <AlertState>
      <AuthState>
        <BuscadorState>
          <MovimientosState>
            <Router>
              <div className="fixed right-0 top-0 mx-4 my-24">
                <Alert />
              </div>
              <Switch>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/loginJWT">
                  <LoginJWT />
                </Route>
                <BaseTemplate>
                  <Route exact path="/">
                    <Home />
                  </Route>
                </BaseTemplate>
              </Switch>
            </Router>
          </MovimientosState>
        </BuscadorState>
      </AuthState>
    </AlertState>
  );
}

export default App;
